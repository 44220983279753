import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';

export default function App() {
  const [, forceUpdate] = useState({});
  const fields = useRef<Array<Array<{ element: HTMLDivElement | null, content: number }>>>([]);
  
  const getLeftoverFields = () => {
    const current = fields.current;
    let amount = 0;

    for (let i = 0; i < current.length; i++) {
      for (let j = 0; j < current[i].length; j++) {
        if (current[i][j] && current[i][j].content !== 0) {
          amount++;
        }
      }
    }

    return 16 - amount;
  };


  const checkEnd = () => {
    // TODO: Check game is finnished
  }

  const generate = useCallback((amount: number) => {
    const leftoverFields = getLeftoverFields()
    if (leftoverFields === 0) {
      checkEnd()
    }

    let generated = 0
    while (generated < (leftoverFields > amount ? amount : leftoverFields)) {
      const x = Math.floor(Math.random() * 4)
      const y = Math.floor(Math.random() * 4)

      if (fields.current[y][x].content === 0) {
        fields.current[y][x].content = 2
        generated++
      }
    } 
    forceUpdate({});
  }, [])

  const init = useCallback(() => {
    fields.current = Array(4).fill(null).map(() => 
      Array(4).fill(null).map(() => ({
        element: null,
        content: 0,
      }))
    );

    generate(2);
    forceUpdate({});
  }, [generate]);

  const move = useCallback((vector: { x: number, y: number }) => {
    const current = fields.current;
    const size = current.length;
  
    const [startX, endX, stepX] = vector.x > 0 ? [size - 1, 0, -1] : [0, size - 1, 1];
    const [startY, endY, stepY] = vector.y > 0 ? [size - 1, 0, -1] : [0, size - 1, 1];
  
    for (let y = startY; y !== endY + stepY; y += stepY) {
      for (let x = startX; x !== endX + stepX; x += stepX) {
        if (current[y][x].content !== 0) {
          while (true) {
            const nextX = x + vector.x;
            const nextY = y + vector.y;
  
            if (nextX < 0 || nextX >= size || nextY < 0 || nextY >= size) {
              break;
            }
  
            if (current[nextY][nextX].content === 0) {
              current[nextY][nextX].content = current[y][x].content;
              current[y][x].content = 0;
              x = nextX;
              y = nextY;
            } else if (current[nextY][nextX].content === current[y][x].content) {
              current[nextY][nextX].content *= 2;
              current[y][x].content = 0;
              break;
            } else {
              break;
            }
          }
        }
      }
    }

    generate(1);
    forceUpdate({});
  }, [generate])
  

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'w')
        move({ x: 0, y: -1 })
      else if (event.key === 'a')
        move({ x: -1, y: 0 })
      else if (event.key === 's')
        move({ x: 0, y: 1 })
      else if (event.key === 'd')
        move({ x: 1, y: 0 })
    };

    document.addEventListener('keydown', handleKeyDown);
    init()
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    };
  }, [init, move])

  return (
    <main>
      <div id='game'>
        {fields.current.map((array, rIndex) => {
          return (
            <div className='row' key={rIndex}>
              {array.map((element, cIndex) => {
                let colorIndex = Math.log2(element.content) / 11;
                
                const r = Math.round(238 - (140 * colorIndex));
                const g = Math.round(228 - (140 * colorIndex));
                const b = Math.round(218 - (140 * colorIndex));
                
                return (
                  <div 
                    className={`field ${element.content === 0 ? 'empty' : 'notEmpty'}`} 
                    key={`${rIndex}-${cIndex}`}
                    style={{
                      backgroundColor: element.content === 0 ? '#CDC1B4' : `rgb(${r}, ${g}, ${b})`,
                      color: colorIndex > 0.5 ? '#f9f6f2' : '#776e65'
                    }}
                  >
                    {element.content !== 0 ? element.content : ''}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <button type="reset">Reset</button>
    </main>
  );
}
